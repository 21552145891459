import React from "react";
import {
    ArrayField,
    AutocompleteInput,
    Button,
    Datagrid,
    Edit,
    NumberField,
    SimpleForm,
    TextField,
    TextInput,
    BooleanField,
    DateField,
    FunctionField,
    ImageField
} from "react-admin";
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { Grid, Typography } from "@mui/material";
import VerifiedIcon from '@mui/icons-material/Verified';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import orderStatuses from '../data/orderStatuses.json';
import vatCodes from '../data/vatCodes.json';


const vatCode = (record) => {
    const vendor = record?.vendorName;
    return vatCodes[vendor];
}

const shippingMethod = (record) => {
    switch (record?.shipping_lines[0].method_id) {
        case 'local_pickup':
            return 'Nouto liikkeestä';
        case 'wcv_pro_vendor_shipping':
            return 'Postitus';
        default:
            return 'Postitus';
    }
}

const OrderIdHeader = () => (
    <Typography variant="h2">Tilausnumero</Typography>
);

const CustomerNoteHeader = () => (
    <Typography variant="h4">Lisätiedot</Typography>
);

const TotalHeader = () => (
    <Typography variant="h4">Tilauksen summa (€)</Typography>
    );
const ShippingTotalHeader = () => (
    <Typography variant="h4">Toimituskulut (€)</Typography>
);
const DateCreatedHeader = () => (
    <Typography variant="h4">Tilauspvm</Typography>
);
const PaymentMethodHeader = () => (
    <Typography variant="h4">Maksutapa</Typography>
);
const VendorHeader = () => (
    <Typography variant="h4">Myyjä</Typography>
);
const OrderItemHeader = () => (
    <Typography variant="h4">Tilatut kirjat</Typography>
);

const VatNumberHeader = () => (
    <Typography variant="h4">Y-tunnus</Typography>
);
const BillingAddressHeader = () => (
    <Typography variant="h3">Laskutusosoite</Typography>
);

const ShippingAddressHeader = () => (
    <Typography variant="h3">Toimitusosoite</Typography>
);
export const OrderDetailView = props => (
    <Edit  {...props} >
        
        <SimpleForm >
        
        <Grid container alignContent={true} alignItems="flex-start" spacing={1} 
        className="printable"
        >
        <Grid item md={12} sm={12} sx={{ padding: 0.1, margin: 0.4}}>
            <OrderIdHeader />
            <TextField source="id" />
        </Grid>
        <Grid item md={12} sm={12} sx={{ borderRadius: 1, margin: 0.4}}>
                    <OrderItemHeader />       
                    <ArrayField label="Myydyt kirjat" source="line_items">
                        <Datagrid bulkActionButtons={false}>
                            <ImageField source="image.src" />
                            <TextField source="product_id" label="ID" />
                            <TextField label="Nimi" source="name" />
                            <TextField label="Tekijä" source="author"/>
                            <TextField label="Sidosasu" source="binding"/>
                            <TextField label="Hylly" source="shelf"/>
                            <FunctionField
                            label="Myyty määrä"
                            render={record => {
                                const quantity = record.quantity;
                                const color = parseInt(quantity) > 1 ? 'info' : 'none';
                                const attention = parseInt(quantity) > 1 ? 
                                <NotificationImportantIcon sx={{ marginLeft: '10px', marginTop: '1px', size: "large", color: "warning"}}/> : 
                                '';
                                return (
                                    <div>
                                    <Button size='large' variant="contained" color={color}>
                                        {quantity}{attention}
                                    </Button>
                                    </div>
                                );
                            }}
                        />
                            <TextField label="Myyjä" source="meta_data[0].display_value" />
                            <BooleanField 
                            label="Toimitettu" 
                            TrueIcon={VerifiedIcon} 
                            FalseIcon={UnpublishedIcon}
                            source="shipped" />
                        </Datagrid>
                    </ArrayField>
                </Grid>
                <Grid md={12} sm={12} sx={{ padding: 0.2, borderRadius: 1, margin: 0.4}}>
                    <CustomerNoteHeader />
                        <TextField source="customer_note" multiline minRows={2} label="Tilauksen kommentti" />
                </Grid>
                <Grid container lg={12} md={12} sm={12} sx={{ borderRadius: 1, margin: 0.4}}>
                    <Grid  item lg={6} md={6} sm={6} >
                        <Grid  >
                            <TotalHeader />
                            <NumberField locales="fi-FI"
                                options={{ style: 'currency', currency: 'EUR' }} source="total"/>
                        </Grid>
                        <Grid >
                            <ShippingTotalHeader />
                            <NumberField locales="fi-FI"
                                options={{ style: 'currency', currency: 'EUR' }} source="shipping_total" />
                        </Grid>
                        <Grid >
                            <VendorHeader />
                            <TextField source="vendorName"/>
                        </Grid>
                    </Grid>
                    <Grid  item lg={6} md={6} sm={6} >
                        <Grid >
                            <DateCreatedHeader />
                            <DateField label="Tilauspvm" source="date_created" />
                        </Grid>
                        <Grid >
                            <PaymentMethodHeader />
                            <TextField label="Maksutapa" source="payment_method_title"/>
                        </Grid>
                        <Grid >
                            <VatNumberHeader />
                            
                            <FunctionField 
                                render={record => `${vatCode(record)}`}
                            />

                        </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} sx={{ padding: 0.1}}>
                        <Typography variant="subtitle2">Verotus</Typography>
                        <Typography variant="caption">Marginaaliverotus, ei sisällä vähennettävää arvonlisäveroa.</Typography>
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{  padding: 0.1, margin: 0.4}}>
                    {/* Grid item for AutocompleteInput */}
                    <Grid item lg={4} md={4} sm={4}>
                        <AutocompleteInput
                            sx={{ innerHeight: 2 }}
                            variant="outlined"
                            label="Tilauksen tila"
                            optionText="translated"
                            optionValue="name"
                            source="status"
                            choices={orderStatuses}
                        />
                    </Grid>

                    {/* Grid item for FunctionField */}
                    <Grid item lg={4} md={5} sm={5} sx={{ pt: 1, margin: 0.4 }}>
                    <Typography variant="h5" sx={{ mt: -5 }}>Toimitustapa</Typography>
                        <FunctionField sx={{ marginLeft: 8  }}
                            
                            render={record => `${shippingMethod(record)}`}
                        />
                    </Grid>
                </Grid>


            <Grid item md={12} sm={12} sx={{  padding: 0.1, margin: 0.4}}>
                <Grid container md={8} sm={8} sx={{padding: 0.1}}>
                        <Grid item md={4} sm={4} >
                                <BillingAddressHeader />
                                <Grid sx={{padding: 0.1, marginTop: 1.5}}>                                
                                <TextInput label="Nimi" source="name" />
                            </Grid>
                            <Grid>
                                <TextInput label="Yritys" source="billing.company"/>
                            </Grid>
                            <Grid>
                                <TextInput label="Osoite 1" source="billing.address_1"/>
                                <TextInput label="Osoite 2" source="billing.address_2"/>
                            </Grid>
                            <Grid>
                                <TextInput label="Postinumero" source="billing.postcode"/><span> </span>
                                <TextInput label="Kaupunki" source="billing.city"/>
                            </Grid>
                            <Grid>
                                <TextInput label="Sähköposti" type="email" source="billing.email" />
                                </Grid>
                            <Grid>
                                <TextInput type="tel" label="Puhelin" source="billing.phone" />
                            </Grid>
                            
                        </Grid>
                        <Grid item md={4} sm={4} >
                                <ShippingAddressHeader />
                                <Grid sx={{padding: 0.1, marginTop: 1.5}}>
                                <TextInput label="Nimi" source="shippingName" />
                            </Grid>
                            <Grid>
                                <TextInput label="Yritys" source="shipping.company"/>
                            </Grid>
                            <Grid>
                                <TextInput label="Osoite 1" source="shipping.address_1"/>
                                <TextInput label="Osoite 2" source="shipping.address_2"/>
                            </Grid>
                            <Grid>
                                <TextInput label="Postinumero" source="shipping.postcode"/><span> </span>
                                <TextInput label="Kaupunki" source="shipping.city"/>
                            </Grid>
                            <Grid>
                                <TextInput label="Sähköposti" type="email" source="shipping.email" />
                                </Grid>
                            <Grid>
                                <TextInput type="tel" label="Puhelin" source="shipping.phone" />
                            </Grid>
                            
                        </Grid>    
                        
                    </Grid>         
        </Grid>
        
                
            </Grid>
        </SimpleForm>
        
        
    </Edit>
);